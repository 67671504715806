const compare = (a, b) => {
  if (
    Object.prototype.hasOwnProperty.call(a, 'position') &&
    Object.prototype.hasOwnProperty.call(b, 'position')
  ) {
    if (a.position > b.position) return 1
    if (a.position === b.position) return 0
    if (a.position < b.position) return -1
  }

  return 0
}

export const sortPresentationVersions = (versions) => {
  if (Array.isArray(versions)) {
    return versions.sort(compare)
  }

  return versions
}
