import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import slugify from 'slugify'
import { withI18next } from 'gatsby-plugin-i18n'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import Container from '../components/Container'
import SectionTitle from '../components/SectionTitle'
import VersionsList from '../components/VersionsList'
import { sortPresentationVersions } from '../utils/sortPresentationVersions'

const PresentationTemplate = (props) => {
  const { presentation } = props.data
  const { title, slug: presentationSlug, language, versions } = presentation

  if (versions && versions.length > 0 && typeof window !== 'undefined') {
    const sortedVersions = sortPresentationVersions(versions)
    const versionsSlug = slugify(sortedVersions[0].title, { lower: true })
    navigate(`/${language}/presentation/${presentationSlug}/${versionsSlug}`)
  }

  return (
    <Layout language={language}>
      <main>
        <LightSection>
          <Container maxWidth="640px">
            <SectionTitle as="h1">{title}</SectionTitle>

            {versions && (
              <VersionsList
                versions={versions}
                presentationSlug={presentationSlug}
                presentationLanguage={language}
              />
            )}
          </Container>
        </LightSection>
      </main>
    </Layout>
  )
}

PresentationTemplate.propTypes = {
  data: PropTypes.object,
  intl: PropTypes.shape({
    locale: PropTypes.string,
  }),
}

export default withI18next()(PresentationTemplate)

export const pageQuery = graphql`
  query PresentationTemplate($id: String!, $language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
    presentation: presentations(id: { eq: $id }) {
      id
      title
      slug
      language
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      versions {
        id
        title
        content
        position
      }
    }
  }
`
